<template>
  <div class="usefull-info fade-in-top">
    <div class="text-info">
      <h2>U izradi....  <i class="fas fa-paint-roller"></i></h2>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .usefull-info {
    margin-top: 100px;
    h2 {
      font-size: 35px;
      text-align: center; 
    }
    ul {
      margin-top: 0;
    }
  }
</style>